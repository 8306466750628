// Footer.js

import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Row } from 'simple-flexbox';

const Footer = () => {
  return (
    <Paper elevation={3} style={{ position: 'fixed', bottom: 0, width: '100%', textAlign: 'center',boxShadow:'none' }}>
      <Row vertical="center" horizontal="center" style={{ padding: '10px' }}>
        <Typography variant="body2" color="textSecondary">
            Terms of Use
        </Typography>
        <Row horizontal="center">
          <Typography variant="body2" color="textSecondary" style={{ marginRight: '20px' }}>
            &copy; {new Date().getFullYear()} CollabKare. All rights reserved.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Contact Us
          </Typography>
        </Row>
      </Row>
    </Paper>
  );
};

export default Footer;
