// Your React component file
import React from "react";
import Backdrop from "@mui/material/Backdrop";
import "./loader.css";

const CollabKareLoader = () => {
  return (
    <>
      <div className="loader_section">
        <Backdrop open={true}>
          <div className="main_loader">
            <div>
              <svg id="page-loader">
                <circle cx="75" cy="75" r="20" style={{ animation: 'loader1 4s linear infinite' }}></circle>
                <circle cx="75" cy="75" r="35" style={{ animation: 'loader2 4s linear infinite' }}></circle>
                <circle cx="75" cy="75" r="50" style={{ animation: 'loader3 4s linear infinite' }}></circle>
                <circle cx="75" cy="75" r="65" style={{ animation: 'loader4 4s linear infinite', transition: 'stroke-dashoffset 0.3s ease-in-out' }}></circle>
              </svg>
            </div>
            <div>
              <p className="loadingText">Loading</p>
            </div>
          </div>
        </Backdrop>
      </div>
    </>
  );
};

export default CollabKareLoader;
