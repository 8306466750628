import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBmzPJF-LdrKzba3u4pt5PLLMpqOn-SpH0',
  authDomain: 'collabkare-250512.firebaseapp.com',
  databaseURL: 'https://collabkare-250512.firebaseio.com',
  projectId: 'collabkare-250512',
  storageBucket: 'collabkare-250512.appspot.com',
  messagingSenderId: '871290887238',
  appId: '1:871290887238:web:28402f0a92f723e78d3e47',
  measurementId: 'G-MZ4C0J5VET'
};
firebase.initializeApp(firebaseConfig);

// Access Firebase services using the imported modules
const auth = firebase.auth(); // Initialize Firebase Auth

// Export the auth instance
export { auth };
const db = firebase.firestore();
// export default db
export default db;
export const storage = firebase.storage();
export const functions = firebase.functions();
