import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SLUGS from 'resources/routesConstants';
import LoadingComponent from 'components/loading/loader';

const DashboardComponent = lazy(() => import('../components/Homepage'));
const Profile = lazy(() => import('../components/Profile'));
const Security = lazy(() => import('../components/Security'));
const Licenses = lazy(() => import('../components/Licenses'));
const Subscription = lazy(() => import('../components/Subscriptions'));
const Support = lazy(() => import('../components/Support'));




function PrivateRoutes({userRole}) {
    return (
        <Suspense fallback={<LoadingComponent/>}>
            <Switch>
                <Route exact path={SLUGS.dashboard} component={DashboardComponent} />
                <Route exact path={SLUGS.profile} component={Profile} />
                {userRole.includes(0) && (
                <Route exact path={SLUGS.subscriptions} component={Subscription}/>
                )}
                <Route exact path={SLUGS.security} component={Security} />
                <Route exact path={SLUGS.licenses} component={Licenses}/>
                <Route exact path={SLUGS.support} component={Support}/>
                <Redirect to={SLUGS.dashboard} />
            </Switch>
        </Suspense>
    );
}

export default PrivateRoutes;
