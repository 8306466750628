// idleTimeoutMiddleware.js

let idleTimeout;

const resetIdleTimeout = (dispatch) => {
  clearTimeout(idleTimeout);
  idleTimeout = setTimeout(() => {
    dispatch({ type: 'LOGOUT' });
  }, 5 * 60 * 1000); // 5 minutes in milliseconds
};

const idleTimeoutMiddleware = ({ dispatch }) => (next) => (action) => {
  if (action.type === 'USER_ACTIVITY') {
    resetIdleTimeout(dispatch);
  }

  return next(action);
};

export default idleTimeoutMiddleware;
