import React from 'react';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
const PrivatePolicy = () => {
    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg">
                <p><strong>Last Updated: October 3, 2022</strong></p>
                <p><strong>PRIVACY POLICY</strong></p>
                <p>Clarify Health Solutions, Inc. (“Clarify”) values your privacy. In this Privacy Policy (“Policy”), we describe how we collect, use, and disclose information that we obtain about visitors to our website (the “Site”), users of the Clarify mobile applications (the “Applications”) and the services available through our Site (collectively, the “Services”), and how we use and disclose that information.</p>
                <p>The Clarify Services have been developed for hospitals and medical groups (“Providers”) and for patients who have subscribed to the Services (“Patients”) to: (1) facilitate and coordinate patient medical care and the administration of medical services by Providers and personnel who are authorized to access and use the Services, including, without limitation, administrators, physicians, nurses, and other caregivers (“Authorized Personnel”); (2) allow Providers to communicate with patients and/or their authorized family members and third party caregivers “(“Authorized Caregiver”) about Patient care and post-treatment progress; and (3) provide Patients and Authorized Caregivers with information about their medical condition and related Provider services.</p>
                <p>Clarify understands the importance of a Patient’s relationship with his or her health care providers and the importance of keeping your Personal Information private. Personal information identifies you as an individual, such as your name, postal address, email address, date of birth, and telephone number (“Personal Information”).</p>
                <p>Health Information is a part of the Personal Information that we collect from Patients. Health Information is generally described as information that we collect, receive or create about a Patient and their healthcare.</p>
                <p>As an example, we may collect Personal Information about Authorized Personnel or an Authorized Caregiver who uses the Services to help a Patient. And we may collect information about a Patient that is unrelated to their healthcare, such as basic registration, creating a profile, and information from third parties that is included within a Patient’s profile.</p>
                <p><strong>This Privacy Policy describes our practices in connection with information that we collect through your use of the Services and through the Site or Applications. You can visit the Site at any time without providing Personal Information, however, the Site may collect information about your visit automatically as described below “Information We Collect Automatically.” By using the Services and/or the Site, you agree to the terms and conditions of this Privacy Policy.</strong></p>
                <p><strong>What Personal Information Do We Collect About You and Why?</strong></p>
                <p><strong>Personal Information We Collect From Providers</strong></p>
                <p>If you are an Authorized Personnel user, we collect Personal Information about you when the Provider subscribes to the Services as well as when you register to use the Services. The Personal Information about Providers and Authorized Personnel that we collect includes, without limitation, the Provider’s and Authorized Personnel’s name, position, specialty, email address, phone number, and business postal address. We do not collect Health Information about Providers or their Authorized Personnel.</p>
                <p><strong>Personal Information We Collect From Patients and Authorized Caregivers</strong></p>
                <p>If you are a Patient, we collect Personal Information about you when you register to use the Services and through your or your Authorized Caregiver’s use of the Services, including when you, your Authorized Caregiver and your Provider communicate with each other.</p>
                <p>If you are an Authorized Caregiver, we collect Personal Information about you, including, without limitation, your name, email address, phone number and your relationship to the Patient. When communicating with the Provider in using the Services, the Patient, Authorized Caregivers, Provider and its Authorized Personnel may disclose Personal Information about the Patient, which may include Health Information. We do not collect Health Information about Authorized Caregivers.</p>
                <p>To register to use the Services as a Patient, you must provide your name, primary address, email address and date of birth. If a Patient or Authorized Caregiver uses the “Link Online Accounts” feature of the Services, we will ask you to provide the user names and passwords for those online accounts that the Patient has authorized Clarify to access in order to collect other Personal Information regarding the Patient and Patient’s medical history that is maintained by third parties Patient has identified in Patient’s Account as “Authorized Third Parties.” The Personal Information the Patient may permit us to obtain from these Authorized Third Parties may include, without limitation, demographic information, financial information, medical claims history, and other Health Information.</p>
                <p>We also may collect additional optional information from you, including, for example, Social Security number, employment history, health care insurance information, and demographic data, however, you are not required to provide us with this information to use the Services.</p>
                <p><strong>How We Use Your Personal Information</strong></p>
                <p>We may use your Personal Information, as follows:</p>
                <ul>
                    <li>To provide our Services to you, to communicate with you about your use of our Services, to respond to your inquiries, and for other customer service purposes.</li>
                    <li>To tailor the educational and other content and information that we may send you, to offer personalized help and instructions, and to otherwise personalize your experiences while using the Site, Applications, or our Services.</li>
                    <li>To better understand how users access and use our Site, Applications, and Services, both on an aggregated and individualized basis, in order to improve our Site, Applications, and Services and respond to user desires and preferences, and for other research and analytical purposes.</li>
                    <li>To send you important information regarding the Services, changes to our terms, conditions, and policies and/or other administrative information.</li>
                    <li>For our business purposes, such as data collection and analysis, audits, developing new products, and enhancing and improving our Site, Applications, and the Services.</li>
                    <li>As we believe to be necessary or appropriate: (a) under applicable law, including laws outside your state or country of residence; (b) to comply with legal process; (c) to respond to requests from public and government authorities including public and government authorities outside your state or country of residence; (d) to enforce our terms and conditions; (e) to protect our operations or those of any of our affiliates; (f) to protect our rights, privacy, safety or property, and/or that of our affiliates, you or others; and (g) to allow us to pursue available remedies or limit the damages that we may sustain.</li>
                </ul>
                <p><strong>How We Share Your Personal Information</strong></p>
                <p>We may disclose your Personal Information, including Health Information (defined below) as follows:</p>
                <ul>
                    <li>If you are a Patient, to your Provider, its Authorized Personnel and your Authorized Caregivers, without further authorization for purposes of treatment, payment or operations; for other uses or disclosures permitted by law; or for purposes related to such uses or disclosures.</li>
                    <li>If you are an Authorized Caregiver, to the Patient and his/her Provider and that Provider’s Authorized Personnel.</li>
                    <li>To our third party service providers who provide services such as website hosting, data gathering, data analysis, payment processing, infrastructure provision, IT services, customer service, email delivery services, credit card processing, backup, auditing services and other similar services.</li>
                    <li>To a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock (including in connection with any bankruptcy or similar proceedings).</li>
                    <li>As we believe to be necessary or appropriate: (a) under applicable law, including laws outside your state or country of residence; (b) to comply with legal process; (c) to respond to requests from public and government authorities including public and government authorities outside your state or country of residence; (d) to enforce our terms and conditions; (e) to protect our operations or those of any of our affiliates; (f) to protect our rights, privacy, safety or property, and/or that of our affiliates, you or others; and (g) to allow us to pursue available remedies or limit the damages that we may sustain.</li>
                    <li>To third parties for research or similar purposes, but only if such Personal Information has been de-identified.</li>
                </ul>
                <p><strong>What is Health Information?</strong></p>
                <p>Health Information is a part of the Personal Information that we collect about Patients and falls into two categories:</p>
                <p>“Protected Health Information” is individually identifiable health information which is created or received by or on behalf of a covered entity (for example, a health care provider or health plan) and which relates to the past, present or future physical or mental health or condition of an individual, the provision of health care to an individual, or the past, present or future payment for the provision of health care to an individual. Protected Health Information is further defined by the federal Health Insurance Portability and Accountability Act of 1996 (HIPAA) and is subject to the protections of HIPAA.</p>
                <p>“Additional Health Information” is a subset of Health Information that does not fall within the category of Protected Health Information above. For example, this would encompass information related to past, present or future physical or mental health or conditions, the provision of health care to an individual, or the past, present or future payment for the provision of health care to an individual but with the difference that such information was not created or received by us from or on behalf of a covered entity. We may collect Additional Health Information in connection with the Site or Applications.</p>
                <p><strong>How We Use and/or Share Protected Health Information and Additional Health Information</strong></p>
                <p>We may use and/or disclose Protected Health Information and Additional Health Information to provide the Services as described in this policy, except our use and disclosure of Protected Health Information is further limited by the main federal health privacy law known as the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”) and the Notice of Privacy Practices available from your Provider.</p>
                <p>We will use and disclose Protected Health Information only to provide Services to the Patient or the Provider, for those uses and disclosures permitted by HIPAA, and the agreement that we have with your Provider. In the event that there is a conflict or inconsistency about the handling of Protected Health Information between (i) this Privacy Policy and (ii) our compliance obligations with HIPAA and contractual commitments with Providers, then the latter will govern.</p>
                <p><strong>Non-Personal Information We Collect Automatically</strong></p>
                <p>We may automatically collect the following information about your use of our Site or Services through cookies, web beacons, and other technologies: your domain name; your browser type and operating system; web pages you view; links you click; your IP address; the length of time you visit our Site or use our Services; and the referring URL, or the webpage that led you to our Site, and the following: access time, browser type, device ID, domain name, IP address, page views and referring URL. We also may collect the following information about your use of the Application: mobile device ID; location and language information; device name and model; operating system type, name, and version; your activities within the Application; and the length of time that you are logged into our Application. We may combine this information with other information that we have collected about you, including, where applicable, your user name, name, and other personal information. Please see the section “Our Use of Cookies and Other Tracking Mechanisms” below for more information.</p>
                <p><strong>Our Use of Cookies and Other Tracking Mechanisms</strong></p>
                <p>We and our third party service providers use cookies and other tracking mechanisms to track information about your use of our Site or Services. We may combine this information with other personal information we collect from you (and our third party service providers may do so on our behalf).</p>
                <p>Currently, our systems do not recognize browser “do-not-track” requests. You may, however, disable certain tracking as discussed in this section (e.g., by disabling cookies); you also may opt-out of targeted advertising by following the instructions in the Third Party Ad Network section.</p>
                <p>Cookies: Cookies are alphanumeric identifiers that we transfer to your computer’s hard drive through your web browser for record-keeping purposes. Some cookies allow us to make it easier for you to navigate our Site and Services, while others are used to enable a faster log-in process or to allow us to track your activities at our Site and Service. There are two types of cookies: session and persistent cookies.</p>
                <ul>
                    <li>Session Cookies: Session cookies exist only during an online session. They disappear from your computer when you close your browser or turn off your computer. We use session cookies to allow our systems to uniquely identify you during a session or while you are logged into the Site. This allows us to process your online transactions and requests and verify your identity, after you have logged in, as you move through our Site.</li>
                    <li>Persistent Cookies: Persistent cookies remain on your computer after you have closed your browser or turned off your computer. We do not currently use persistent cookies on the Site.</li>
                </ul>
                <p><strong>Disabling Cookies: </strong>Most web browsers automatically accept cookies, but if you prefer, you can edit your browser options to block them in the future. The Help portion of the toolbar on most browsers will tell you how to prevent your computer from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. Visitors to our Site who disable cookies will be able to browse certain areas of the Site, but some features may not function.</p>
                <p><strong>Local Storage Objects: </strong>We may use Flash Local Storage Objects (“Flash LSOs”) to store your Site preferences and to personalize your visit. Flash LSOs are different from browser cookies because of the amount and type of data stored. Typically, you cannot control, delete, or disable the acceptance of Flash LSOs through your web browser. For more information on Flash LSOs, or to learn how to manage your settings for Flash LSOs, go to the Adobe Flash Player Help Page, choose “Global Storage Settings Panel” and follow the instructions. To see the Flash LSOs currently on your computer, choose “Website Storage Settings Panel” and follow the instructions to review and, if you choose, to delete any specific Flash LSO.</p>
                <p><strong>Third Party Analytics: </strong>We use automated devices and applications, such as Google Analytics, to evaluate usage of our Site and, to the extent permitted, our Applications. We also may use other analytic means to evaluate our Services. We use these tools to help us improve our Services, performance and user experiences. These entities may use cookies and other tracking technologies to perform their services. We do not share your personal information with these third parties.</p>
                <p>With respect to Google Analytics specifically, we may collect the some or all of the following information about your use of our Site and Applications, and we may be able to connect this information to other information we have about you:</p>
                <ul>
                    <li>Pages visited, time of visit, and time spent on each page of the webpages</li>
                    <li>Type of web browser</li>
                    <li>Type of operating system (OS)</li>
                    <li>Screen resolution</li>
                    <li>IP address</li>
                </ul>
                <p><strong>Third-Party Links</strong></p>
                <p>Our Site and Services may contain links to third-party websites. Any access to and use of such linked websites is not governed by this Policy, but instead is governed by the privacy policies of those third party websites. We are not responsible for the information practices of such third party websites.</p>
                <p><strong>Security of Personal Information</strong></p>
                <p>We use reasonable administrative, technical, and physical measures to protect Personal Information under our control, and Protected Health Information is specifically per the HIPAA rules. Unfortunately, no data transmission over the Internet or data storage system can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you might have with us has been compromised), please immediately notify us of the problem by contacting us at <a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="6a060f0d0b062a09060b18030c13020f0b061e024409050744">[email&#160;protected]</a></p>
                <p>You should take steps to protect against unauthorized access to your password, phone, and computer by, among other things, signing off after using a shared computer, choosing a robust password that nobody else knows or can easily guess, and keeping your log-in and password private. We are not responsible for any lost, stolen, or compromised passwords or for any activity on your account via unauthorized password activity.</p>
                <p><strong>Access to My Personal Information</strong></p>
                <p>You may modify Personal Information that you have submitted by logging into your account and updating your profile information. Please note that copies of information that you have updated, modified or deleted may remain viewable in cached and archived pages of the Site or Application for a period of time.</p>
                <p>You may also contact us directly if you would like to review, correct, update, delete or otherwise limit our use of your Personal Information that has been previously provided to us by sending us an email at <a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="a0ccc5c7c1cce0c3ccc1d2c9c6d9c8c5c1ccd4c88ec3cfcd8e">[email&#160;protected]</a> In your request, please make clear what information you would like to have changed, whether you would like to have your Personal Information deleted from our database or otherwise let us know what limitations you would like to put on our use of your Personal Information. We will try to comply with your request as soon as reasonably practicable. Please note that in order to comply with certain requests to limit use of your Personal Information we may need to terminate your account with us and your ability to access and use the Services, and you agree that we will not be liable to you for such termination. Although we will use reasonable efforts to do so, you understand that it may not be technologically possible to remove from our systems every record of your Personal Information. The need to back up our systems to protect information from inadvertent loss means a copy of your Personal Information may exist in a non-erasable form that will be difficult or impossible for us to locate or remove.</p>
                <p>We will retain your Personal Information for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or allowed by law.</p>
                <p><strong>What Choices Do I Have Regarding Use of My Personal Information?</strong></p>
                <p>We may send periodic promotional or informational emails to you, provided that such messages are not based on Protected Health Information. You may opt-out of such communications by following the opt-out instructions contained in the e-mail. Please note that it may take up to 10 business days for us to process opt-out requests. If you opt-out of receiving emails about recommendations or other information we think may interest you, we may still send you e-mails about your account or any Services you have requested or received from us.</p>
                <p><strong>Children Under 18</strong></p>
                <p>The Services are not permitted for use by individuals under the age of eighteen (18) unless they have provided the written consent of their parents or legal guardians, and we request that these individuals do not provide Personal Information to us.</p>
                <p><strong>Special Information for California Consumers</strong></p>
                <p>California residents may request a list of certain third parties to which we have disclosed personally identifiable information about you for their own direct marketing purposes. You may make one request per calendar year. In your request, please attest to the fact that you are a California resident and provide a current California address for your response. You may request this information in writing by contacting us at: <a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="a6cac3c1c7cae6c5cac7d4cfc0dfcec3c7cad2ce88c5c9cb88">[email&#160;protected]</a> Please allow up to thirty (30) days for a response.</p>
                <p><strong>Changes to this Policy</strong></p>
                <p>This Policy is current as of the Effective Date set forth above. We may change this Policy from time to time, so please be sure to check back periodically. We will post any changes to this Policy on our Site, at www.clarifyhealth.com/privacy-policy. If we make any changes to this Policy that materially affect our practices with regard to the Personal Information we have previously collected from you, we will endeavor to provide you with notice in advance of such change by highlighting the change on our Site, or otherwise notify you at the email address provided by you at the time you registered for the Services, whichever occurs earlier. Any change to this Privacy Policy will be effective for all information that we maintain, even information in existence before the change. Your use of the Site following these changes means that you accept the revised Privacy Policy.</p>
                <p><strong>Contacting Clarify</strong><br />
If you have questions about the privacy aspects of our Services or would like to make a complaint about our compliance with this Privacy Policy, you may contact us at <a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="49252c2e2825092a25283b202f30212c28253d21672a2624">[email&#160;protected]</a>, by calling +1-415-343-4655, or at our mailing address: Clarify Health Solutions, Inc. 75 Hawthorne Street, Floor Four, San Francisco, CA, 94105.</p>

            </Container>
        </React.Fragment>
    )
}
export default PrivatePolicy