import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import PrivateSection from 'routes/PrivateSection';
import PublicRoutes from 'routes/PublicRoutes';
import { connect } from 'react-redux';

function Routes({isAuthenticated,userRole}) {
    const { pathname } = useLocation();
    // eslint-disable-next-line no-unused-vars
    const [width, height] = useWindowSize();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return isAuthenticated ? <PrivateSection userRole={userRole} /> : <PublicRoutes />;
    // return <PublicRoutes />
}

const mapStateToProps = (state) => ({
    isAuthenticated:state.isAuthenticated,
    userRole:state.userRole
});

export default connect(mapStateToProps)(Routes);
