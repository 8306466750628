// reducers/authReducer.js
const initialState = {
    isAuthenticated: false,
    userData: null, 
    userRole:[]
  };
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LOGIN_SUCCESS':
        return {
          ...state,
          isAuthenticated: true,
          userRole:action.payload.data?.data[0].roles,
          userData:action.payload
        };
      case 'LOGIN_FAILURE':
      case 'LOGOUT':
        return {
          ...state,
          isAuthenticated: false,
          userRole:[],
          userData: null, 
        };
      default:
        return state;
    }
  };
  
  export default authReducer;
  