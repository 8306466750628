import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import {
  Grid,
  TextField,
  Button,
  Link,
  CssBaseline,
  Paper,
  Stack,
  FormControl,
  IconButton,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import "./signup.css";
import db, { auth } from "auth/firebase";
import { useFormik } from "formik";
import * as Yup from "yup";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PasswordStrengthBar from "react-password-strength-bar";
import { Blocks } from "react-loader-spinner";
import Cookies from "universal-cookie";
import CollabKareSnackbar from "components/Snackbar";
import Loader from "components/loading/loader";
import logo from "../../assets/icons/logo.svg";
import SearchComponent from "components/Orginization";
import { functions } from "auth/firebase";
import ReCAPTCHA from "react-google-recaptcha";

const Signup = () => {
  const cookies = new Cookies();
  let [organization, setOrganization] = useState("");
  const [isOrgExists, setIsOrgExists] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState({ msgType: "", msg: "" });
  const [showLoader, setShowLoader] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  let [orgImage, setOrgImage] = useState(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  useEffect(() => {
    let org = cookies.get("organization");
    setOrgImage(cookies.get("orgImage"));
    if (org) {
      setOrganization(org);
      setShowSignup(true);
    } else {
      setShowSignup(false);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      confirmationemail: "",
      first_name: "",
      middle_name:"",
      last_name: "",
      phone: "",
      password: "",
      passwordconfirm: "",
      addressLine: "",
      area: "",
      state: "",
      city: "",
      country: "",
      pincode: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email  is required"),
      confirmationemail: Yup.string()
        .required("Confirm your email")
        .oneOf([Yup.ref("email"), null], "Emails must match"),
      first_name: Yup.string().required("First Name Line is required"),
      last_name: Yup.string().required("Last Name is required"),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must be AplphaNumberic with one special character and one UpperCase at least 8 characters long"
        ),
      passwordconfirm: Yup.string()
        .required("Confirm your password")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      state: Yup.string().required("State is required"),
      country: Yup.string().required("Country is required"),
      pincode: Yup.string()
        .required("Pincode is required")
        .matches(/^\d{7}$/, "Invalid pincode (should be 7 digits)"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setShowLoader(true);
      let payload = {
        email: values.email,
        confirmationemail: values.confirmationemail,
        first_name: values.first_name,
        middle_name:values.middle_name,
        last_name: values.last_name,
        phone: values.phone,
        password: values.password,
        passwordconfirm: values.passwordconfirm,
        addressLine: values?.addressLine,
        area: values?.area,
        state: values?.state,
        city: values?.city,
        country: values?.country,
        pincode: values?.pincode,
        roles: [13],
        delegatedApplications:[
          "KarePortal_Roles_Patient_Services_Appointments_Privileges_Read",
          "KarePortal_Roles_Patient_Services_Appointments_Privileges_Write",
          "KarePortal_Roles_Patient_Services_Appointments_Privileges_Update",
          "KarePortal_Roles_Patient_Services_Appointments_Privileges_Delete"
          ]
      };
      if (!isOrgExists) {
        createUser(payload);
        resetForm();
      } else {
        setShowLoader(false);
        setModalOpen(true);
      }
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function extractSubdomainFromEmail(email) {
    const regex = /@([a-zA-Z0-9.-]+)$/;
    const match = email.match(regex);
    const domain = match ? match[1] : null;

    if (domain) {
      const parts = domain.split(".");
      // Exclude the last part (TLD), return everything before it
      return parts.slice(0, -1).join(".");
    }

    return null;
  }

  const validateUser = async (data) => {
    try {
      const validateTenantUserExists = functions.httpsCallable(
        "validateTenantUserExists"
      );
      const response = await validateTenantUserExists({
        email: data,
        tenantId: organization,
      });

      if (!response?.data.success) {
        setIsOrgExists(true);
      } else {
        setIsOrgExists(false);
      }
    } catch (error) {
      return { error: error.message };
    }
  };

  const createUser = async (payload) => {
    let collection = "Registration";
    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);
    const baseUrl = urlObject.origin;

    try {
      // Create user account
      const userCredential = await auth.createUserWithEmailAndPassword(
        payload.email,
        payload.password
      );
      let data = {
        ...payload,
        userId: userCredential.user.uid,
      };
      await db.collection(collection).doc(userCredential.user.uid).set(data);
      let url = `${baseUrl}/setUpUser?org=${organization}&userId=${userCredential.user.uid}&user=${formik.values.email}`;
      const actionCodeSettings = {
        url: url,
        handleCodeInApp: true,
      };
      await userCredential.user.sendEmailVerification(actionCodeSettings);
      setShowLoader(false);
      setShowSnackbar(true);
      setSnackbarMsg({
        msg: "Please check your email for verification link",
        msgType: "success",
      });
    } catch (error) {
      setShowLoader(false);
      setShowSnackbar(true);
      setSnackbarMsg({
        msg: error.message,
        msgType: "error",
      });
      console.error("Registration error:", error.message);
    }
  };

  const handleOrginization = (data) => {
    setShowSignup(true);
    cookies.set("organization", data.value);
    setOrganization(data.value);
  };
  return (
    <div>
    <CollabKareSnackbar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        message={snackbarMsg.msg}
        messageType={snackbarMsg.msgType}
      />
      {showSignup && (
        <Grid container component="main" className={"root"}>
          <CssBaseline />
          <Grid item xs={false} sm={4} md={6} className={"image"} />
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            component={Paper}
            elevation={6}
            style={{ boxShadow: "none" }}
          >
            <div className={"paper"}>
             
                <div>
                  <div className="paper112">
                    <img
                      src={orgImage}
                      style={{
                        width: "300px",
                        height: "90px",
                      }}
                    />
                  </div>
                  <h4 style={{ textAlign: "center" }}>
                    {" "}
                    YOUR JOURNEY STARTS HERE
                  </h4>
                  <p style={{ textAlign: "center" }}>
                    Enjoy unlimited features for free for 30days. Start your
                    free trial today or
                    <a href="/bookdemo" style={{ color: "#007AFF" }}>
                      {" "}
                      book a demo
                    </a>
                  </p>
                  <h5>
                    <div className="h4Text">Create my account</div>
                  </h5>
                  <form className={"form"} onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="First Name"
                          size="small"
                          id="first_name"
                          name="first_name"
                          placeholder="Your first name"
                          error={
                            formik.touched.first_name &&
                            Boolean(formik.errors.first_name)
                          }
                          helperText={
                            formik.touched.first_name &&
                            formik.errors.first_name
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.first_name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                      <TextField
                          fullWidth
                          variant="outlined"
                          label="Middle Name"
                          size="small"
                          id="middle_name"
                          name="middle_name"
                          placeholder="Your middle name"
                          error={
                            formik.touched.middle_name &&
                            Boolean(formik.errors.middle_name)
                          }
                          helperText={
                            formik.touched.middle_name &&
                            formik.errors.middle_name
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.middle_name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Last Name"
                          name="last_name"
                          placeholder="Your last name"
                          size="small"
                          error={
                            formik.touched.last_name &&
                            Boolean(formik.errors.last_name)
                          }
                          helperText={
                            formik.touched.last_name && formik.errors.last_name
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.last_name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Email"
                          name="email"
                          placeholder="Your email address"
                          type="email"
                          size="small"
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email &&
                            (formik.errors.email ||
                              (isOrgExists && (
                                <span className={"passwordValidation"}>
                                  email is already exists
                                </span>
                              )))
                          }
                          onChange={formik.handleChange}
                          onBlur={(e) => {
                            formik.handleBlur(e);
                            validateUser(e.target.value);
                          }}
                          value={formik.values.email}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Confirm Email"
                          name="confirmationemail"
                          placeholder="Your email address"
                          type="email"
                          size="small"
                          error={
                            formik.touched.confirmationemail &&
                            Boolean(formik.errors.confirmationemail)
                          }
                          helperText={
                            formik.touched.confirmationemail &&
                            formik.errors.confirmationemail
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.confirmationemail}
                          onPaste={(e) => e.preventDefault()}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          className={"margin"}
                          variant="outlined"
                          fullWidth
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Password
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            size="small"
                            variant="outlined"
                            autoComplete="password"
                            error={
                              formik.touched.password &&
                              Boolean(formik.errors.password)
                            }
                            helperText={
                              formik.touched.password && formik.errors.password
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            labelWidth={70}
                          />
                          {formik.touched.password &&
                            formik.errors.password && (
                              <div className={"passwordValidation"}>
                                {formik.errors.password}
                              </div>
                            )}
                          <PasswordStrengthBar
                            password={formik.values.password}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          name="passwordconfirm"
                          label="Confirm Password"
                          type="password"
                          error={
                            formik.touched.passwordconfirm &&
                            Boolean(formik.errors.passwordconfirm)
                          }
                          helperText={
                            formik.touched.passwordconfirm &&
                            formik.errors.passwordconfirm
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.passwordconfirm}
                          onPaste={(e) => e.preventDefault()}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <PhoneInput
                          country={"us"}
                          className={"reacttelinput"}
                          name="phone"
                          required
                          size="small"
                          error={
                            formik.touched.phone && Boolean(formik.errors.phone)
                          }
                          helperText={
                            formik.touched.phone && formik.errors.phone
                          }
                          onChange={(value, data, event, formattedValue) => {
                            formik.setFieldValue("phone", formattedValue);
                            formik.setTouched({
                              ...formik.touched,
                              phone: true,
                            });
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                          variant="outlined"
                          label="Phone number"
                        />
                      </Grid>{" "}
                      <Grid item xs={12} sm={6}></Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="addressLine"
                          name="addressLine"
                          placeholder="Your Address Line"
                          size="small"
                          error={
                            formik.touched.addressLine &&
                            Boolean(formik.errors.addressLine)
                          }
                          helperText={
                            formik.touched.addressLine &&
                            formik.errors.addressLine
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.addressLine}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Area"
                          name="area"
                          placeholder="Area"
                          size="small"
                          error={
                            formik.touched.area && Boolean(formik.errors.area)
                          }
                          helperText={formik.touched.area && formik.errors.area}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.area}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="State"
                          name="state"
                          placeholder="state"
                          size="small"
                          error={
                            formik.touched.state && Boolean(formik.errors.state)
                          }
                          helperText={
                            formik.touched.state && formik.errors.state
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="City"
                          name="city"
                          placeholder="City"
                          size="small"
                          error={
                            formik.touched.city && Boolean(formik.errors.city)
                          }
                          helperText={formik.touched.city && formik.errors.city}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Country"
                          name="country"
                          placeholder="country"
                          size="small"
                          error={
                            formik.touched.country &&
                            Boolean(formik.errors.country)
                          }
                          helperText={
                            formik.touched.country && formik.errors.country
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.country}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Pincode"
                          name="pincode"
                          placeholder="pincode"
                          size="small"
                          error={
                            formik.touched.pincode &&
                            Boolean(formik.errors.pincode)
                          }
                          helperText={
                            formik.touched.pincode && formik.errors.pincode
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.pincode}
                        />
                      </Grid>
                      <Grid item sx={12} sm={12}>
                        <ReCAPTCHA
                          sitekey="6Le8Z1EpAAAAAG4inaruCYbR1yZ6gChxdjQ09o_s"
                          onChange={(value) => setRecaptchaValue(value)}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent={"flex-end"}
                          sx={{ marginTop: "15px" }}
                        >
                          <div>
                            <Link
                              href="/login"
                              variant="body2"
                              style={{ color: "#007AFF", marginLeft: "90px" }}
                            >
                              Sign in instead
                            </Link>
                          </div>
                          <div>
                            <Button variant="contained" type="submit">
                            {showLoader ? (
                                  <CircularProgress size={24} color="inherit" />
                                ) : (
                                  " Submit"
                                )}
                            </Button>
                          </div>
                        </Stack>
                      </Grid>
                    </Grid>
                  </form>
                </div>
            </div>
          </Grid>
        </Grid>
      )}

      {!showSignup && <SearchComponent onTitleClick={handleOrginization} />}
    </div>
  );
};

export default Signup;
